import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
//import {Link} from 'gatsby'
import Layout from '../../components/Layout'
import WorkList from '../../components/WorkList'
import WorkCategoriesMenu from '../../components/WorkCategoriesMenu'

const Works = ({ data, location }) => {
  return (
    <Layout>
      <Helmet title="Identity and Logo design" />
      <WorkCategoriesMenu location={location} />
      <WorkList works={data} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query IdQuery($path: String) {
    allJavascriptFrontmatter(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { path: { regex: $path }, published: { ne: false } }
      }
    ) {
      edges {
        node {
          ...WorkData
        }
      }
    }
  }
`

export default Works
